import { useState, useRef, useCallback } from "react";
import * as VideoExpress from "@vonage/video-express";
import useBackgroundBlur from "../hooks/useBackgroundBlur";

export default function useRoom() {
  const { startBackgroundBlur } = useBackgroundBlur();
  let roomRef = useRef(null);
  let publisherOptionsRef = useRef(null);
  const [camera, setCamera] = useState(null);
  const [screen, setScreen] = useState(null);
  const [localParticipant, setLocalParticipant] = useState(null);
  const [connected, setConnected] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [networkStatus, setNetworkStatus] = useState(null);
  const [cameraPublishing, setCameraPublishing] = useState(false);

  const addParticipants = ({ participant }) => {
    setParticipants((prev) => [...prev, participant]);
  };

  const removeParticipants = ({ participant }) => {
    setParticipants((prev) =>
      prev.filter((prevparticipant) => prevparticipant.id !== participant.id)
    );
  };

  const addLocalParticipant = ({ room }) => {
    if (room) {
      setLocalParticipant({
        id: room.participantId,
        name: room.participantName,
      });
    }
  };

  const startRoomListeners = useCallback(() => {
    if (roomRef.current) {
      roomRef.current.on("connected", () => {
        console.log("Room: connected");
      });
      roomRef.current.on("disconnected", () => {
        setNetworkStatus("disconnected");
        console.log("Room: disconnected");
      });
      roomRef.current.camera.on("created", () => {
        setCameraPublishing(true);
        console.log("camera publishing now");
      });
      roomRef.current.on("activeSpeakerChanged", (participant) => {
        console.log("Active speaker changed", participant);
      });

      roomRef.current.on("reconnected", () => {
        setNetworkStatus("reconnected");
        console.log("Room: reconnected");
      });
      roomRef.current.on("reconnecting", () => {
        setNetworkStatus("reconnecting");
        console.log("Room: reconnecting");
      });
      roomRef.current.on("participantJoined", (participant) => {
        console.log(participant);
        addParticipants({ participant: participant });
        console.log("Room: participant joined: ", participant);
      });
      roomRef.current.on("participantLeft", (participant, reason) => {
        removeParticipants({ participant: participant });
        console.log("Room: participant left", participant, reason);
      });
    }
  }, []);

  const createCall = useCallback(
    async (
      { apikey, sessionId, token },
      roomContainer,
      userName,
      videoEffects,
      publisherOptions
    ) => {
      if (!apikey || !sessionId || !token) {
        throw new Error("Check your credentials");
      }

      roomRef.current = new VideoExpress.Room({
        apiKey: apikey,
        sessionId: sessionId,
        token: token,
        roomContainer: "roomContainer",
        maxVideoParticipantsOnScreen: 50,
        participantName: userName,
        managedLayoutOptions: {
          layoutMode: "grid",
          screenPublisherContainer: "screenSharingContainer",
        },
      });
      startRoomListeners();

      if (videoEffects.backgroundBlur) {
        const outputVideoStream = await startBackgroundBlur();

        publisherOptionsRef.current = Object.assign({}, publisherOptions, {
          style: {
            buttonDisplayMode: "off",
            nameDisplayMode: "auto",
            audioLevelDisplayMode: "off",
          },

          videoSource: outputVideoStream.getVideoTracks()[0],
          name: userName,
          showControls: true,
        });
      } else {
        publisherOptionsRef.current = Object.assign({}, publisherOptions, {
          style: {
            buttonDisplayMode: "off",
            nameDisplayMode: "auto",
            audioLevelDisplayMode: "off",
          },
          name: userName,
          showControls: true,
        });
      }

      console.log(
        "[useRoom] - finalPublisherOptions",
        publisherOptionsRef.current
      );
      roomRef.current
        .join({ publisherProperties: publisherOptionsRef.current })
        .then(() => {
          setConnected(true);
          setCamera(roomRef.current.camera);
          setScreen(roomRef.current.screen);
          addLocalParticipant({ room: roomRef.current });
        })
        .catch((e) => console.log(e));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const togglePIP = async () => {
    if (document.pictureInPictureElement) {
      const canvasVideo = document.pictureInPictureElement;
      canvasVideo.pause();
      await document.exitPictureInPicture();

      return
    }

    const videos = Array.from(document.querySelectorAll('#roomContainer video'))
    const canvas = document.createElement("canvas");

    // canvas width will be the max width of the videos
    canvas.width = Math.max(...videos.map(video => video.videoWidth))
    // canvas height will be the sum of the height of the videos
    canvas.height = videos.map(video => video.videoHeight).reduce((a, b) => a + b, 0)

    const ctx = canvas.getContext("2d");
    // create a new video element for canvas
    const canvasVideo = document.createElement("video");
    canvasVideo.srcObject = canvas.captureStream();

    let began = false; // rPiP needs video's metadata
    anim();
    await canvasVideo.play();
    began = true;
    await canvasVideo.requestPictureInPicture();

    function anim() {
      const videos = Array.from(document.querySelectorAll('#roomContainer video'))
      let offsetY = 0;
      for (let i = 0; i < videos.length; i++) {
        const video = videos[i];
        const width = video.videoWidth;
        const height = video.videoHeight;
        ctx.drawImage(video, (canvas.width - width) / 2, offsetY, width, height);
        offsetY += height;
      }

      if (offsetY > canvas.height) {
        canvas.height = offsetY
      }

      // if we are still in PiP mode
      if (!began || document.pictureInPictureElement === canvasVideo) {
        requestAnimationFrame(anim);
      } else {
        // kill the stream
        canvasVideo.srcObject.getTracks().forEach(track => track.stop());
      }
    }
  };

  return {
    createCall,
    connected,
    camera: camera,
    screen: screen,
    room: roomRef.current,
    participants,
    networkStatus,
    cameraPublishing,
    localParticipant,
    togglePIP
  };
}
