import { useMemo, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { UserContext } from "./context/UserContext";
import VideoRoom from "./components/VideoRoom";
import Error from "./components/Error";
import WaitingRoom from "./components/WaitingRoom";
import EndCall from "./components/EndCall";

import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import "./App.css";

const theme = () => {
  return createTheme({
    palette: {
      type: "light",
      primary: {
        main: "#2D53B3",
        background: "rgba(45, 83, 179, 0.1)",
      },
      secondary: {
        main: "#2D53B3",
      },
      white: {
        main: "#F6F7F9",
      },
      black: {
        main: "#1E1E1E",
      },
      toolbarBackground: {
        main: "transparent",
      },
      activeButtons: {
        green: "#2ECC71",
        red: "#F65656",
      },
    },
  });
};

export default function App() {
  const [user, setUser] = useState({
    videoEffects: {
      backgroundBlur: false,
      virtualBackground: false,
    },
    defaultSettings: {
      publishAudio: true,
      publishVideo: true,
      audioSource: undefined,
      videoSource: undefined,
    },
  });

  const userValue = useMemo(() => ({ user, setUser }), [user, setUser]);

  return (
    <ThemeProvider theme={theme()}>
      <Router>
        <UserContext.Provider value={userValue}>
          <Switch>
            <Route path="/room/:roomName/:sessionId/end">
              <EndCall />
            </Route>
            <Route exact path="/room/:roomName" component={VideoRoom} />
            <Route path="/error" component={Error}></Route>
            <Route exact path="/" component={WaitingRoom}></Route>
            <Route path="*">
              <Redirect to={{ pathname: "/" }} />
            </Route>
          </Switch>
        </UserContext.Provider>
      </Router>
    </ThemeProvider>
  );
}
